<template>
  <div class="flix-flex flix-flex-center flix-gap-5">
    {{ $t("services.withService") }}
    <inputSelect
      style="margin-bottom: 0"
      :key="data"
      :settings="{ value: data, default: data, values: values }"
      :callback="setSave"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: [Number, String]
  },
  data() {
    return {
      data: JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
        .include.weekdays[this.index][2],
      values: this.$store.getters["service/tags"]
    };
  },
  computed: {},
  mounted() {
    if (!isNaN(parseInt(this.data))) {
      this.data = Object.keys(this.values)[0];
      this.setSave(this.data);
    }
  },
  methods: {
    setSave(value) {
      this.data = value;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.include.weekdays[this.index][2] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
